.shipping{
    padding: 0 1em;
}
 .method {
    padding: 2em 0em;
}
 h2{
    font-size: 1.2857142857em;
    line-height: 1.3em;
    color: #333333;
    font-weight: normal;
 }
 .contentFieldS{
    border-color: #d9d9d9;
    background: #fff;
    background-clip: padding-box;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    color: #545454;
    display: block;
    
    padding-block-start: 0.35em;
    
    padding-block-end: 0.625em;
    min-inline-size: min-content;
 }
 .contentRowS{
    display: flex;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    padding: 1.1428571429em;
    position: relative;
    zoom: 1;
    color: #545454;
 }
 .contentRowS ~ .contentRowS{
     /* select every contentRow that is preceeded by a content row, so effectively skip the first one */
    border-top: 1px solid #d9d9d9;

 }
 .radioWrapper{
    display: table;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
 }
 .radioInput{
    display: table-cell;
    padding-right: 0.75em;
    white-space: nowrap;
 }
 .shipping input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  } /*
 .radioButton:checked{
    border-width: 7px;
    border-color: #fabdbd;
    
 }*/
 .radioButton{
    border-color: #d9d9d9;
    background-color: white;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    position: relative;
    cursor: pointer;
    vertical-align: -4px;
    border: 1px solid;
 }
 .radioButton:checked{
    accent-color: #e7b405;
    
 }
.radioLabel{
    display: table-cell;
    width: 100%;
    cursor: pointer;
    vertical-align: middle;
    color: #545454;
}
.addressHeader{
    color: #737373;
    padding-right: 1.1428571429em;
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 5em;
    -ms-flex: 0 1 5em;
    flex: 0 1 5em;
    padding-bottom: 0.2857142857em;
}
.addressBody{
    width: 100%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
}
.amount{
    font-size: 1.2857142857em;
    line-height: 1em;
    color: #333333;
}
.radioAccessory{
    display: table-cell;
    text-align: right;
    padding-left: 0.75em;
    white-space: nowrap;
}
.change{
    color:#e7b405;
}
.stepFooter{
    z-index: 2;
    position: relative;
    margin-top: 1em;
}
.stepButton{
    cursor: pointer;
    display: inline-block;
    background-color: #e7b405;
    background-clip: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border: 1px transparent solid;
    border-radius: 5px;
    color: #322626;
    font-weight: 500;
    text-align: center;
    position: relative;
    width: 100%;
    padding: 1.4rem 0;
    writing-mode: horizontal-tb !important;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, box-shadow 0.2s ease-in-out, -webkit-box-shadow 0.2s ease-in-out;
}
.stepButton span{
    font-weight: 550;
    font-size: 1.1rem;
}

.method {
    padding: 2em 0em;
}
.radioSadad{
    display: table-cell;
    width: 30%;
    vertical-align: top;
    color: #545454;
    font-weight: 400;
    padding-right: 1em;
}
.radioSadad img{
    width: 60px;
}
.radioP{
    display: table-cell;
    width: 100%;
    vertical-align:middle;
    color: #545454;
    font-weight: 400;
}
