.listing{
    display: flex;
    width: 100vw;
    flex-direction:column;
    align-items: center;
    justify-content: center;
  }
.products-listing{
    width: 92%;
    display: flex;
    flex-direction: column;
    
   /* background: lightgrey;*/
  }
  