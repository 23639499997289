.thankyouMsg{
  font-size: 26px;
    line-height: 34px;
    margin: 10px 0;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0px;
    font-family: Nanum Myeongjo,serif;
}
@media screen and (max-width: 991px){
.thankyouMsg {
    text-align: center;
    padding-top: 30px;
}}
.preparing{
  margin-top: -18px;
  margin-bottom: 18px;
  font-size: 14px;
  font-family: Nanum Gothic,sans-serif;
  font-weight: 400;
    font-style: normal;
    line-height: 32px;
    letter-spacing: 0;
    -webkit-font-smoothing: antialiased;
    display: block;
    margin-block-start: 1em;
/*     margin-block-end: 1em;
 */   
  margin-inline-start: 0px;
    margin-inline-end: 0px;
}

@media screen and (max-width: 991px){
.preparing {
    text-align: center;
    padding-top: 30px;
    margin-bottom: 18px;

}
}
table{
  display: table;
  border-collapse: separate;
  box-sizing: border-box;
  text-indent: initial;
  border-spacing: 2px;
  border-color: grey;
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 1em;

}
tbody {
  display: table-row-group;
  vertical-align: middle;
  border-color: inherit;
}
tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}

th {
  display: table-cell;
  vertical-align: inherit;
  padding-left: 1em;
  padding-top: 0;
  padding-bottom: 0;
}
.productDescription{
  text-align: left;
  width: 100%;
}
.product-titleT{
  display: block;
  width: 1em;
  min-width: 100%;
  font-weight: 500;

}
.product-titleT a{
  font-size: 1.2em;
  font-weight: 600;
  color: black;
}
.status{
  display: block;
}
.paymentStatus{
  color: #5BAE50;
}

.time{
  color: #717171;
  font-size: 1em;
  white-space: pre-line;
}
.chevronR{
  padding-left: 1em;
}
.tableWrapper{
  padding: 1.5em 0;
  border-bottom: 1px solid rgba(175,175,175,0.34);
  border-top: 1px solid rgba(175,175,175,0.34);

}
.tableWrapper ~ .tableWrapper{
  padding: 1.5em 0;
  border-bottom: 1px solid rgba(175,175,175,0.34);
  border-top: 1px solid transparent;

}

.productTable tr{
}
.borders{
  padding: 1em 0;
  border-bottom: 1px solid rgba(175,175,175,0.34);
  border-top: 1px solid rgba(175,175,175,0.34);

}
.orderBreaker{
  padding: 1em;
}