.clearfix:before, .AffixColumn-root:before, .Container:before, .HeroHeader-container:before, .Modal-content:before, .StaffGrid-modal:before, .ObiStrip-content:before, .ProductView:before, .Product--full:before, .SiteHeader-notice:before, .AjaxCart:before, .AjaxCart-item:before, .ContentSection:before, .ObiStrip:before, .Triptych:before, .StaffGrid-modal_slider_wrapper:before, .Example-row:before, .Example-content:before, .Example-specimen:before, .Example-large_specimen_content:before, .clearfix:after, .AffixColumn-root:after, .Container:after, .HeroHeader-container:after, .Modal-content:after, .StaffGrid-modal:after, .ObiStrip-content:after, .ProductView:after, .Product--full:after, .SiteHeader-notice:after, .AjaxCart:after, .AjaxCart-item:after, .ContentSection:after, .ObiStrip:after, .Triptych:after, .StaffGrid-modal_slider_wrapper:after, .Example-row:after, .Example-content:after, .Example-specimen:after, .Example-large_specimen_content:after {
  content: " ";
  display: table;
}

header {
  background-color: #2c3f4e;
  position: sticky;
  top: 0;
  z-index: 9999;
  transition: margin .5s;
  position: relative;
}
.anyflexbox .banner {
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}
.banner {
  padding: 0;
  background-size: cover;
}
.nav-top {

  margin: 0 auto;
  max-width: 100%;
  padding: 0 20px;
  width: 100%;
  height: 90px;
  position: relative;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* to make equal 3 sub navs since box sizing is border box the internal items wont expand it they will get cropped */
}
@media (max-width: 991px){
.nav-top {
    padding: 0 20px;
}
}
.navLeft, .navRight {
  display: flex;
  align-items: center;
  justify-content: space-between;
  
}
.nav-logo {
  /* background: darkred;*/
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90px;
  max-width: 200px;
  /* the trick in the width of the mother container to give fixed padding to its children */
}
@media (max-width: 991px){
.nav-logo {
    max-width: 60px;
    padding: 0;
}}
.nav-logo img {
  width: 100%;
}
@media (min-width: 1000px){
.nav-logo img {
    max-height: 4em;
}
}
#username{
  font-size: 13px;
}
.user-txt {
  float: left;
  position: relative;
    height: 90px;
    width: 40px;
    height: 100%;
    background-color: transparent;
    border: none;
    padding: 0;
    box-sizing: border-box;

}
.user-txt h3,
.nav-bottom h3,
.nav-link {
  color: white;
  font-weight: 700;
  margin: 0;
  padding: 0;
  letter-spacing: 0.03em;
  /*  set margin and padding to 0 to get rid of the text defaults  */
  width: 100px;
  /*so name won't dynamically change length*/
  white-space: nowrap;
  overflow: hidden;
  /*to cut extra*/
  text-overflow: ellipsis;
  /*if cut x put dots*/
  /*background: skyblue;*/
  text-align: right;
  /*text is separate than the h3 element*/
}
a:focus,
a:hover {
/*   color: white;
 */  text-decoration: none;
}
.user {
  /* border: 1px solid wheat;*/
  display: flex;
  align-items: center;
  justify-content: center; 
  width: 40px;
}
.userContainer{
  width: 130px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cart-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 1px solid wheat;*/
  position: relative;
  width: 40px;
  /* for overlapping items: absolute one of the children of flex box and align to center */

}

.cart-logo img {
  z-index: 0;
  width: 10vw;
}
.cart-logo p {
  font-size: 10px;
  font-weight: 700;
  color: #2c3f4e;
  /*   padding: 0 0 5vw 2vw;
 */ /*   position: absolute;
 */
}
.cartCircle {
  width: 14px;
  height: 14px;
  border-radius: 5vw;
  position: absolute;
  right: 6px;
  /*     top: 30%;
 */
  transform: translateY(-45%);
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background: #e7b405;

}

.nav-bottom a {
  /* styling link same as a */
  color: white;
}

.nav-bottom p {
  font-size: 3.2vw;
  letter-spacing: 0.04vw;
  padding-left: 0.7vw;
  margin-top: 2vw;
  /*   background: rebeccapurple;
 */
}
.nav-bottom div {
  width: 70vw;
  display: flex;
  /*       background: limegreen;
 */
  align-items: center;
}
.show,
.dropdown {
  display: flex;
  flex-direction: column;
}
  