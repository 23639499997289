.mapsTitle {
  position: absolute;
  top: -0.6rem;
  left: 1rem;
  font-size: medium;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.3);
  line-height: 2rem;
}
.googleMaps {
  position: relative;
}
.locate {
  position: absolute;
  bottom: 15rem;
  right: 1rem;
  font-size: medium;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.3);
  line-height: 2rem;
}
.main p{
    font-family: "Nanum Gothic", sans-serif;
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0;
    -webkit-font-smoothing: antialiased;
    line-height: initial;
    margin-block-start: .2em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}
.form-itemsA input {

  background-color: white;
  color: #333333;
/*  
 border-color: #d9d9d9;

 border: 1px transparent solid;
 */
  font: 1.2rem helvetica;
  padding: 1rem;
  width: 100%;
  background-clip: padding-box;
  border-radius: 5px;
  /*background: darkseagreen*/
}
.form-itemsA h1 {
  font-size: 1.4rem;
}
.form-itemsA li {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  width: 100%;
  /* background: mediumvioletred; */
}
.dirty {
    border: 2px solid #e22120 ;
}
input:invalid{
    border: 2px solid #e22120 ;

}
.clean{
    border: 1px #d9d9d9 solid;

}

::placeholder{
    font-style: italic;
    color: #d9d9d9;
}
.main li p {
  color: #e22120;

}
.errorField{
    border-color:#e22120 ;

}
.form-itemsA {
    display: flex;
    flex-direction: column;
    padding: 0.8rem 0rem;
    /*     border-bottom: 0.1rem #c0c0c0 solid;
     */
    border-radius: 0.5rem;
    list-style-type: none;
    align-items: center;
  
    /*    background: orange;
     */
  }
  
.load {
  position: absolute;
  bottom: 15rem;
  right: 50%;
  font-size: medium;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.3);
  line-height: 2rem;
}
.address-container {
  display: flex;
  flex-direction: column;
}
label {
  display: block;
  font-size: 12px;
  letter-spacing: 1.2px;
  font-weight: 700;
}
