@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0');


.breadcrumb {
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style-type: none;
  margin-block-start: 1em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 1em;
  background-color: white;
  font-size: .9rem;
}

@media (max-width: 999px) {
  .breadcrumb {
  }
}

.breadcrumb__item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #737373;
}

.breadcrumb a {
  text-decoration: none;
  color: #e7b405;
  -webkit-transition: color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out;
  cursor: pointer;
  display: inline;
  text-align: -webkit-match-parent;
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.breadcrumb .material-symbols-outlined {
  color: #737373;
  fill: currentColor;
  width: 10px;
  height: 10px;
  margin: 0 0.6428571429em;
  display: inline-block;
  vertical-align: middle;
  font-size: 0.8571428571em;
}
.breadcrumb > li + li:before {
  padding: 0;
  content: "";
}
.breadcrumb__item--current {
  font-weight: 500;
  color: #333333;
}
.breadcrumb span {
  cursor: pointer;
  display: inline;
  text-align: -webkit-match-parent;
  margin: 0;
  padding: 0;
  list-style-type: none;
  
}
