/*google signin*/
.googleContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  /*     height: 20vh;
 */
 height: 100%;
  width: 100%;

  /* background: lightyellow*/
}
.login-with-google-btn {
  transition: background-color 0.3s, box-shadow 0.3s;
  width: 100%;
  padding: 12px 16px 12px 42px;
  border: none;
  

  border-radius: 3px;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25);

  color: #757575;
  font-size: 14px;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
  background-color: white;
  background-repeat: no-repeat;
  background-position: 12px 11px;
}
#google-button{
  margin: .425rem 0;
}
.login-with-apple-btn {
  transition: background-color 0.3s, box-shadow 0.3s;
  width: 100%;
  padding: 12px 16px 12px 42px;
  border: none;
  

  border-radius: 3px;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25);

  color: #757575;
  font-size: 14px;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDIyLjE4NSAyNyIgdmlld0JveD0iMCAwIDIyLjE4NSAyNyI+PHBhdGggZD0iTTE4LjQzNiAxNC4yNzFjMC0yLjIyNSAxLjIxNi00LjE2NiAzLjAyLTUuMTk0LS45NDItMS4xNTYtMi4zNTktMi4xOTItMy45MjEtMi40OTYtMi4xMy0uNDE1LTMuMzQ1LjI3Ni00LjEzMS41NTMtLjc4Ni4yNzctMS44My41MjYtMS44My41MjZzLTEuMDQzLS4yNDktMS44My0uNTI2QzguOTU5IDYuODU3IDcuNzQ0IDYuMTY1IDUuNjE0IDYuNThzLTMuOTkgMi4xOTItNC43NDYgMy43NzZjLTEuNDU4IDMuMDU3LS44NTggNi45MDMuMTcyIDkuNjY5IDEuMDI5IDIuNzY3IDMuNzUgNi42MzMgNS44NiA2Ljk0NCAxLjUwMS4yMjEgMi40My0uODE2IDQuNjc0LTEuMDc5IDIuMjQ0LjI2MyAzLjE3MyAxLjMgNC42NzQgMS4wNzkgMi4xMS0uMzExIDQuODMxLTQuMTc3IDUuODYtNi45NDQuMDI1LS4wNjguMDUxLS4xMzguMDc2LS4yMDhDMTkuOTg3IDE4LjkzNSAxOC40MzYgMTYuNzg0IDE4LjQzNiAxNC4yNzF6TTExLjA0MSA2LjA3NWMwIDAgMi4wODcuMjc3IDMuOTgyLTEuODc1czEuMzU2LTQuMTg4IDEuMzU2LTQuMTg4LTIuMDg3LS4yNzctMy45ODIgMS44NzVTMTEuMDQxIDYuMDc1IDExLjA0MSA2LjA3NXoiLz48L3N2Zz4=);
  background-color: white;
  background-repeat: no-repeat;
  background-position: 12px 11px;
  background-size: 20px 20px;
}
.login-with-facebook-btn {
  transition: background-color 0.3s, box-shadow 0.3s;
  width: 100%;
  padding: 12px 16px 12px 42px;
  border: none;
  

  border-radius: 3px;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25);

  color: #757575;
  font-size: 14px;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

  background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMTYgMTYiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxwYXRoIGQ9Ik0xNCAwSDJDLjg5NyAwIDAgLjg5NyAwIDJ2MTJjMCAxLjEwMy44OTcgMiAyIDJoMTJjMS4xMDMgMCAyLS44OTcgMi0yVjJjMC0xLjEwMy0uODk3LTItMi0yeiIgZmlsbD0iIzE5NzZEMiIvPjxwYXRoIGQ9Ik0xMy41IDhIMTFWNmMwLS41NTIuNDQ4LS41IDEtLjVoMVYzaC0yYTMgMyAwIDAgMC0zIDN2Mkg2djIuNWgyVjE2aDN2LTUuNWgxLjVsMS0yLjV6IiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZmlsbD0iI0ZBRkFGQSIvPjxtZXRhZGF0YT48cmRmOlJERiB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiIHhtbG5zOnJkZnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvMDEvcmRmLXNjaGVtYSMiIHhtbG5zOmRjPSJodHRwOi8vcHVybC5vcmcvZGMvZWxlbWVudHMvMS4xLyI+PHJkZjpEZXNjcmlwdGlvbiBhYm91dD0iaHR0cHM6Ly9pY29uc2NvdXQuY29tL2xlZ2FsI2xpY2Vuc2VzIiBkYzp0aXRsZT0iZmFjZWJvb2ssbG9nbyxzb2NpYWwsY29sb3IsZSIgZGM6ZGVzY3JpcHRpb249ImZhY2Vib29rLGxvZ28sc29jaWFsLGNvbG9yLGUiIGRjOnB1Ymxpc2hlcj0iSWNvbnNjb3V0IiBkYzpkYXRlPSIyMDE4LTAyLTE3IiBkYzpmb3JtYXQ9ImltYWdlL3N2Zyt4bWwiIGRjOmxhbmd1YWdlPSJlbiI+PGRjOmNyZWF0b3I+PHJkZjpCYWc+PHJkZjpsaT5QaXhlbCBJY29uczwvcmRmOmxpPjwvcmRmOkJhZz48L2RjOmNyZWF0b3I+PC9yZGY6RGVzY3JpcHRpb24+PC9yZGY6UkRGPjwvbWV0YWRhdGE+PC9zdmc+);
  background-color: white;
  background-repeat: no-repeat;
  background-position: 12px 11px;
  background-size: 20px 20px;
}
.login-with-an-btn {
  transition: background-color 0.3s, box-shadow 0.3s;
  width: 100%;
  padding: 12px 16px 12px 42px;
  border: none;
  

  border-radius: 3px;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25);

  color: #757575;
  font-size: 14px;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

  background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMTAxIDEwMSIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHBhdGggZD0iTTUwLjQgNTQuNWMxMC4xIDAgMTguMi04LjIgMTguMi0xOC4yUzYwLjUgMTggNTAuNCAxOHMtMTguMiA4LjItMTguMiAxOC4yIDguMSAxOC4zIDE4LjIgMTguM3ptMC0zMS43YzcuNCAwIDEzLjQgNiAxMy40IDEzLjRzLTYgMTMuNC0xMy40IDEzLjRTMzcgNDMuNyAzNyAzNi4zczYtMTMuNSAxMy40LTEzLjV6Ii8+PHBhdGggZD0iTTE4LjggODNoNjMuNGMxLjMgMCAyLjQtMS4xIDIuNC0yLjQgMC0xMi42LTEwLjMtMjIuOS0yMi45LTIyLjlIMzkuM2MtMTIuNiAwLTIyLjkgMTAuMy0yMi45IDIyLjkgMCAxLjMgMS4xIDIuNCAyLjQgMi40em0yMC41LTIwLjVoMjIuNGM5LjIgMCAxNi43IDYuOCAxNy45IDE1LjdIMjEuNGMxLjItOC45IDguNy0xNS43IDE3LjktMTUuN3oiLz48bWV0YWRhdGE+PHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIiB4bWxuczpyZGZzPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwLzAxL3JkZi1zY2hlbWEjIiB4bWxuczpkYz0iaHR0cDovL3B1cmwub3JnL2RjL2VsZW1lbnRzLzEuMS8iPjxyZGY6RGVzY3JpcHRpb24gYWJvdXQ9Imh0dHBzOi8vaWNvbnNjb3V0LmNvbS9sZWdhbCNsaWNlbnNlcyIgZGM6dGl0bGU9InVzZXIsYWNjb3VudCxwcm9maWxlIiBkYzpkZXNjcmlwdGlvbj0idXNlcixhY2NvdW50LHByb2ZpbGUiIGRjOnB1Ymxpc2hlcj0iSWNvbnNjb3V0IiBkYzpkYXRlPSIyMDE3LTExLTE1IiBkYzpmb3JtYXQ9ImltYWdlL3N2Zyt4bWwiIGRjOmxhbmd1YWdlPSJlbiI+PGRjOmNyZWF0b3I+PHJkZjpCYWc+PHJkZjpsaT5MdWPDrWEgR29uesOhbGV6PC9yZGY6bGk+PC9yZGY6QmFnPjwvZGM6Y3JlYXRvcj48L3JkZjpEZXNjcmlwdGlvbj48L3JkZjpSREY+PC9tZXRhZGF0YT48L3N2Zz4=);
  background-color: white;
  background-repeat: no-repeat;
  background-position: 12px 10px;
  background-size: 24px 24px;
}


.form-items input {
  border: 1px transparent solid;

  background-color: white;
    color: #333333;
    border-color: #d9d9d9;
  font: 1.2rem helvetica;
  padding: 1rem;
  width: 100%;    
  background-clip: padding-box;
  border-radius: 5px;
  /*background: darkseagreen*/
}
.form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin:11.7% 4vw;
/*   margin-top: -1.5rem;
 */  /* background: lightyellow*/
}
em{
/*   margin-top: 22%;
 */  color: rgb(92, 92, 92); background-color: rgba(34, 37, 41, 0); font-size: 10px;
}
@media (min-width: 700px) {
  .socialLogins .form-items {
    width: 500px;
  }
}

.form-items {
  display: flex;
  flex-direction: column;
  padding: 0.8rem 0rem;
  /*     border-bottom: 0.1rem #c0c0c0 solid;
 */
  border-radius: 0.5rem;
  list-style-type: none;
  align-items: center;
/*   width: 86vw;
 */
  /*    background: orange;
 */
}
.form-items h1 {
  font-size: 1.4rem;
}
.form-items li {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  width: 100%;
  /* background: mediumvioletred; */
}

/* .form-items button {
    width: 85vw;
}
 */
button.primary {
  background-color: #f0c040;
  width: 100%;
  font-size: 1.2rem;
  border: none;
  box-shadow: 0vw 0.1vw 1vw 0 lightgrey;
  margin-top: 1rem;
}
button {
  font: 1.6rem helvetica;
  padding: 1rem;
  border: 0.1rem #808080 solid;
  border-radius: 0.2rem;
  cursor: pointer;
}
.or {
  position: relative;
  text-align: center;
}
.or h5 {
  font-size: 1.1rem;
  color: #767676;
  font-weight: 400;
  z-index: 2;
  position: relative;
  display: inline-block;
  background-color: #fff;
  padding: 0 0.7rem 0 0.7rem;
}
.divider {
  content: "";
  width: 100%;
  background-color: transparent;
  display: block;
  height: 0.1rem;
  border-top: 0.1rem solid #e7e7e7;
  position: absolute;
  top: 50%;
  margin-top: 0;
  z-index: 1;
}
.googleContainer form {
  width: 100%;
}
.new-user {
  padding-top: 1.5rem;
  font-size: 1.1rem;
}
