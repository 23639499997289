.mapsTitle {
}
.direction {
  position: absolute;
  width: 90%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;

  z-index: 10;
  display: flex;
  background: #fafafa;
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
  padding: 1.25em 1em;
  flex-shrink: 0;
  text-align: left;
  width: 100%;
  align-items: center;
}
.googleMap {
  position: relative;
}
.locatef {
    position: absolute;
    bottom: 4.5em;
    right: .5rem;
    background: none;
    border: none;
    z-index: 10;
    padding: 0;
  }
  .locatef span {
    
    cursor: pointer;
    background: white;
    color: #e7b405;
    font-size: 10vw;
    padding: .2rem;
    border-radius: 50%;
  }
  .load {
  position: absolute;
  bottom: 15rem;
  right: 50%;
  font-size: medium;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.3);
  line-height: 2rem;
}

.loa {
  cursor: pointer;
  display: inline-block;
  background-color: #e7b405;
  background-clip: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px transparent solid;
  border-radius: 5px;
  color: #322626;
  font-weight: 500;
  text-align: center;
  position: absolute;
  width: 94%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  padding: 1rem 0;
  writing-mode: horizontal-tb !important;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out,
    box-shadow 0.2s ease-in-out, -webkit-box-shadow 0.2s ease-in-out;
  z-index: 10;
  bottom: 1em;
}
.loa span {
  font-weight: 550;
  font-size: 1.1rem;
}
.gm-bundled-control-on-bottom {
  bottom: 40vh;
}
