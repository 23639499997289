.mapsTitle{
    position: absolute;
    top: -.6rem;
    left: 1rem;
    font-size: medium;
    z-index: 10;
    background-color: rgba(255, 255, 255, .3);
    line-height: 2rem;
}
.googleMaps{
    position: relative;

}
.locate{
    position: absolute;
    bottom: 15rem;
    right: 1rem;
    font-size: medium;
    z-index: 10;
    background-color: rgba(255, 255, 255, .3);
    line-height: 2rem;
}
.load{
    position: absolute;
    bottom: 15rem;
    right: 50%;
    font-size: medium;
    z-index: 10;
    background-color: rgba(255, 255, 255, .3);
    line-height: 2rem;

}
.address-container{
    display: flex;
    flex-direction: column;
}
label {
    display: block;
    font-size: 12px;
    letter-spacing: 1.2px;
    font-weight: 700;
}