.shipping {
  padding: 0 1em;
}
.method {
  padding: 2em 0em;
}
h2 {
  font-size: 1.2857142857em;
  line-height: 1.3em;
  color: #333333;
  font-weight: normal;
}
.addressTitle {
  font-size: 26px;
  line-height: 34px;
  margin: 4px 0;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0px;
  font-family: Nanum Myeongjo, serif;
  color: #333333;
}
.contentField {
  border-color: #d9d9d9;
  background: #fff;
  background-clip: padding-box;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  color: #545454;
  display: block;
  margin-inline-start: 2px;
  margin-inline-end: 2px;
  padding-block-start: 0.35em;
  padding-inline-start: 0.75em;
  padding-inline-end: 0.75em;
  padding-block-end: 0.625em;
  min-inline-size: min-content;
}
.contentRowS {
  display: block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  zoom: 1;
  color: #545454;
}
.addressS {
  margin: 1.5em 0;
  position: relative;
}
.mapContainer {
  cursor: pointer;
  border-radius: 0.5em;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: 1em;
  transition: border-color 0.2s ease-out;
  border: 2px solid #ebebeb;
  justify-content: center;
}
.contentRow ~ .contentRow {
  /* select every contentRow that is preceeded by a content row, so effectively skip the first one */
  border-top: 1px solid #d9d9d9;
}
.radioWrapper {
  display: table;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
}
.radioInput {
  display: table-cell;
  padding-right: 0.75em;
  white-space: nowrap;
}
.shipping input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
} /*
 .radioButton:checked{
    border-width: 7px;
    border-color: #fabdbd;
    
 }*/
.radioButtonS {
  border-color: #d9d9d9;
  background-color: white;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  position: absolute;
  cursor: pointer;
  border: 1px solid;
  top: 1em;
  right: 1em;
  z-index: 200;
}
.radioButton:checked {
  accent-color: #e7b405;
}
.radioButton:checked ~ .mapContainer {
  border: 2px solid #e7b405;
  background-color: #fffdf4;
}
.underline-effect {
  display: inline-block;
  position: relative;
  line-height: 16px;
  letter-spacing: 1.2px;
  margin-right: 10px;
}
.underline-effect:after {
  content: "";
  display: block;
  height: 2px;
  top: 3px;
  position: relative;
  opacity: 1;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  -ms-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  background-color: #e7b405;
  width: 100%;
}

.radioLabelS {
  display: table-cell;
  width: 100%;
  cursor: pointer;
  vertical-align: middle;
  color: #545454;
  padding-top: 1em;
  padding-bottom: .2em;
}
.radioDays {
  display: table-cell;
  width: 100%;
  vertical-align: bottom;
  color: #545454;
  font-weight: 400;
  font-size: 14px;
}
.amount {
  font-size: 1.2857142857em;
  line-height: 1em;
  color: #333333;
}
.radioAccessory {
  display: table-cell;
  text-align: right;
  padding-left: 0.75em;
  white-space: nowrap;
}
.radioBd {
  font-weight: 500;
  color: #333333;
}
.editAddress {
  padding-bottom: 1em;
}
.stepFooter {
  z-index: 2;
  position: relative;
  margin-top: 1em;
}
.stepButton {
  cursor: pointer;
  display: inline-block;
  background-color: #e7b405;
  background-clip: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px transparent solid;
  border-radius: 5px;
  color: #322626;
  font-weight: 500;
  text-align: center;
  position: relative;
  width: 100%;
  padding: 1.4rem 0;
  writing-mode: horizontal-tb !important;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out,
    box-shadow 0.2s ease-in-out, -webkit-box-shadow 0.2s ease-in-out;
}
.stepButton span {
  font-weight: 550;
  font-size: 1.1rem;
}
/* h2,.mapContainer>*{
  margin: 0 0 1em 0;
}
 */
p {
  font-family: "Nanum Gothic", sans-serif;
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  line-height: 32px;
  letter-spacing: 0;
  -webkit-font-smoothing: antialiased;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
.reset-button {
  background-color: transparent;
  border-width: 0;
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  line-height: inherit;
  padding: 0;
}

.addAddress h1 {
  text-align: center;
}
.addAddress {
  height: 280px;

}
.addAddress span{
  
  color:#e7b405;
  font-size: 90px;
  text-align: center;
}
.material-symbols-outlined {
  font-variation-settings:
  'FILL' 0,
  'wght' 300,
  'GRAD' 0,
  'opsz' 48
}
.radioButtonS {
  border-color: #d9d9d9;
  background-color: white;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  position: absolute;
  cursor: pointer;
  border: 1px solid;
  top: 2em;
  right: 2em;
  z-index: 200;
}

/* --------------------------------- */
/* :root {
    --card-line-height: 1.2em;
    --card-padding: 1em;
    --card-radius: 0.5em;
    --color-green: #558309;
    --color-gray: #e2ebf6;
    --color-dark-gray: #c4d1e1;
    --radio-border-width: 2px;
    --radio-size: 1.5em;
  }
  
  body {
    background-color: #f2f8ff;
    color: #263238;
    font-family: 'Noto Sans', sans-serif;
    margin: 0;
    padding: 2em 6vw;
  }
  
  .grid {
    display: grid;
    grid-gap: var(--card-padding);
    margin: 0 auto;
    max-width: 60em;
    padding: 0;
   
  }
  
  .card {
    background-color: #fff;
    border-radius: var(--card-radius);
    position: relative;
  }
  .card:hover {
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.15);
  }

  
  .radio {
    font-size: inherit;
    margin: 0;
    position: absolute;
    right: calc(var(--card-padding) + var(--radio-border-width));
    top: calc(var(--card-padding) + var(--radio-border-width));
  }
  
    .radio {
      -webkit-appearance: none;
      -moz-appearance: none;
      background: #fff;
      border: var(--radio-border-width) solid var(--color-gray);
      border-radius: 50%;
      cursor: pointer;
      height: var(--radio-size);
      outline: none;
      transition: 
        background 0.2s ease-out,
        border-color 0.2s ease-out;
      width: var(--radio-size); 
    }
      .radio::after {
        border: var(--radio-border-width) solid #fff;
        border-top: 0;
        border-left: 0;
        content: '';
        display: block;
        height: 0.75rem;
        left: 25%;
        position: absolute;
        top: 50%;
        transform: 
          rotate(45deg)
          translate(-50%, -50%);
        width: 0.375rem;
      }
  
      .radio:checked {
        background: var(--color-green);
        border-color: var(--color-green);
      }
    
    
    .card:hover .radio {
      border-color: var(--color-dark-gray);
      
    }
    .card:hover .radio:checked {
        border-color: var(--color-green);
      }

  
  
  .plan-details {
    border: var(--radio-border-width) solid var(--color-gray);
    border-radius: var(--card-radius);
    cursor: pointer;
    display: flex;
    flex-direction: column;
    padding: var(--card-padding);
    transition: border-color 0.2s ease-out;
  }
  
  .card:hover .plan-details {
    border-color: var(--color-dark-gray);
  }
  
  .radio:checked ~ .plan-details {
    border-color: var(--color-green);
  }
  
  .radio:focus ~ .plan-details {
    box-shadow: 0 0 0 2px var(--color-dark-gray);
  }
  
  .radio:disabled ~ .plan-details {
    color: var(--color-dark-gray);
    cursor: default;
  }
  
  .radio:disabled ~ .plan-details .plan-type {
    color: var(--color-dark-gray);
  }
  
  .card:hover .radio:disabled ~ .plan-details {
    border-color: var(--color-gray);
    box-shadow: none;
  }
  
  .card:hover .radio:disabled {
      border-color: var(--color-gray);
    }
  
  .plan-type {
    color: var(--color-green);
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1em;
  }
  
  .plan-cost {
    font-size: 2.5rem;
    font-weight: bold;
    padding: 0.5rem 0;
  }
  
  .slash {
    font-weight: normal;
  }
  
  .plan-cycle {
    font-size: 2rem;
    font-variant: none;
    border-bottom: none;
    cursor: inherit;
    text-decoration: none;
  }
  
  .hidden-visually {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  } */
