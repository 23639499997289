.productO{
    display: flex;
    margin-top: 4vw;
    flex-direction: column;
    border-bottom: 1px solid lightgrey;
  }
  .product-header{
    display: flex;
    position: relative;
    margin-top: 0vw;
    align-items: center;
    /*background: coral;*/
   /* sizing of the container will not take the size of the absolute children */
    
    
    
  }
  .product-header svg{
   /* background: mediumvioletred;*/
  
    z-index: 0;
    /* if u put the position absolute put the parent relative so it will be absolute to its container not to the body */
   /* background: rebeccapurple;*/
  }
  .product-header p{
    margin-left: 1.5vw;
    font-size: .8em;
    z-index: 2;
    position: absolute;
   /* background: royalblue;*/
   
    
  }
  
  .product-body{
    margin-top: 2.5vw;
    display: flex;
    width: 100%;
   /* background: darkseagreen;*/
    margin-bottom: 4vw;
    
  }
  .product-pic{
    width:30%;
    display: flex;
    overflow: hidden;
    /*background: orange;*/
    align-items: flex-start;
   
  }
  .product-pic img{
    width:100%;
   /* background: darkred;*/
  }
  .product-info{
    padding-left: 5vw;
    display: flex;
    flex-direction: column;
    width: 70%;
   /* background: indianred;*/
  }
  .product-title-bold{
    font-weight: 600;
    font-size: 3.8vw;
    color: black;
    text-decoration:none;
  /*  background: forestgreen;*/
  }
  .vendor{
    color: dimgrey;
    font-size: 3.2vw;
    font-weight: 400;
   /* background: silver;*/
  }
  .rating{
    display: flex;
    margin-top: 2vw;
    /*background: skyblue;*/
  }
  .rating span{
    color: #f08804;
   /* -webkit-text-stroke: .3vw #f08804;*/
   
    font-size: 5.5vw;
    margin-right: -.9vw;
    margin-top: -.5vw;
   /* background: sandybrown;*/
  }
  
  .rating .vendor{
    font-size: 3.5vw;
    margin-left: 1vw;
    letter-spacing: 0;
  /*  background: darkslateblue: ;*/
  }
  .price{
    display: flex;
    /*background: yellow;*/
    align-items: flex-start;
   
    margin-top: 2vw;
   /* background: lightgoldenrodyellow;*/
    
  }
  .currency{
    font-size: 3.5vw;
   /* background: steelblue;*/
    padding: 0;
    margin: 0;
    line-height: .75em;
    font-weight: 400;
    letter-spacing: -.12vw;
  }
  .bd{
    font-size: 5.5vw;
   /* background: darkmagenta;*/
    padding: 0;
    margin: 0;
    line-height: .75em;
    margin-left: .2vw;
    
  }
  .fils{
    font-size: 3vw;
  /*  background: red;*/
    padding: 0;
    margin: 0;
    font-weight: 400;
    line-height: .75em;
     /* general rule lineheight .75em */
  }
  
  #products .footer {
    position: absolute;
    bottom: 0;
    border-top: 1px solid #e7e7e7;
    width: 90%;
    padding: 17px 0 17px;
    font-size: 13px;
    color: #555;
  }
  
.white-detail{
    color: white;
    font-size: 3.2vw;
    letter-spacing: .04vw;
  }
  