.order-summary-toggle {
  background: #fafafa;
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
/*   padding: 1.25em 0;
 */  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  text-align: left;
  width: 100%;
  display: inline-block;
  appearance: button;
  cursor: pointer;
}
@media (min-width: 999px) {
  .order-summary-toggle {
    display: none;
  }
}

.order-summary-toggle__inner {
  display: table;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
}
.order-summary-toggle__icon-wrapper {
  display: table-cell;
}
.order-summary-toggle__icon-wrapper {
  vertical-align: middle;
  padding-right: 0.75em;
  white-space: nowrap;
}
.tag{
  /* vertical-align: bottom; */
}
.tagContainer{
  display: flex;
  align-items: center;
}
.order-summary-toggle--show .order-summary-toggle__text--show, .order-summary-toggle--hide .order-summary-toggle__text--hide {
  display: table-cell;
  width: 100%;

}
.order-summary-toggle__text {
  color: #e7b405;
  vertical-align: middle;
  -webkit-transition: color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out;
}
.order-summary-toggle__dropdown {
  vertical-align: middle;
  -webkit-transition: fill 0.2s ease-in-out;
  transition: fill 0.2s ease-in-out;
  fill: #e7b405;

}
.order-summary-toggle__total-recap {
  vertical-align: middle;
  text-align: right;
  padding-left: 0.75em;
  white-space: nowrap;
  display:table-cell;
}
.strike-through{
  text-decoration: line-through;
}
.material-symbols-outlined .tag {
  font-variation-settings:
  'FILL' 1,
  'wght' 400,
  'GRAD' 0,
  'opsz' 48
}
.total-recap__final-price {
  font-size: 1.2857142857em;
  line-height: 1em;
  color: #333333;
}
@media (min-width: 700px) {
  .orderSummary {
    display: none;
  }
}
.orderSummary .amount {
  font-weight: 500;
  font-size: 1.2rem;
  width: 100%;
  text-align: right;
  /*     background: red;
 */
}
.orderSummary svg {
  fill: #e7b405;
  width: 6vw;
  padding-right: 0.75em;
  padding-top: 0.2em;
}
.orderSummary span {
  color: #e7b405;
  font-size: 1.2rem;
  line-height: 1.3em;
}
.expandMore {
  height: auto;
  max-height: 1000px;
  padding: 0 1em 2em 1em;
  transition: all 1s cubic-bezier(0.36, 0.27, 0.23, 1.48);
  background: #fafafa;
}
.expandLess {
  overflow: hidden;
  max-height: 0;
}
.product-picS img,
.product-picS {
  width: 4.6em;
  height: 4.6em;
  border-radius: 8px;
  background: #fff;
  position: relative;
  border: 1px rgba(0, 0, 0, 0.1) solid;
}
.productS {
  display: flex;
  padding-top: 4vw;
  flex-direction: column;
  border-bottom: 1px solid lightgrey;
}
.cartCircleS {
  width: 4vw;
  height: 4vw;
  border-radius: 5vw;
  position: absolute;
  right: -0.75em;
  top: -0.75em;
  /*         transform: translateY(-90%);
 */
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background: rgba(114, 114, 114, 0.9);
}
.product-picS p {
  font-size: 3.5vw;
  font-weight: 700;
  /*      padding: 0 0 5vw 2vw; 
     position: absolute;
 */
}
.priceS {
  display: flex;
  /*background: yellow;*/
  align-items: flex-start;
  padding-left: 4em;
  margin-top: 2vw;
  text-align: right;
  /* background: lightgoldenrodyellow;*/
}
.currency {
  font-size: 3.5vw;
  /* background: steelblue;*/
  padding: 0;
  margin: 0;
  line-height: 0.75em;
  font-weight: 400;
  letter-spacing: -0.12vw;
}
.bd {
  font-size: 5.5vw;
  /* background: darkmagenta;*/
  padding: 0;
  margin: 0;
  line-height: 0.75em;
  margin-left: 0.2vw;
}
.fils {
  font-size: 3vw;
  /*  background: red;*/
  padding: 0;
  margin: 0;
  font-weight: 400;
  line-height: 0.75em;
  /* general rule lineheight .75em */
}
.product-infoS {
  padding-left: 5vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* background: indianred;*/
}
.product-titles a {
  font-weight: 600;
  font-size: 3.8vw;
  color: black;
  text-decoration: none;
  /*  background: forestgreen;*/
}
.vendor {
  color: dimgrey;
  font-size: 3.2vw;
  font-weight: 400;
  /* background: silver;*/
}
.order-summary__section ~ .order-summary__section {
  border-top: 1px solid rgba(175, 175, 175, 0.34);
}
.order-summary__section {
  padding-top: 1.5em;
  padding-bottom: 1.5em;
  display: block;
}
table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 1em;
}
tbody {
  display: table-row-group;
  vertical-align: middle;
  border-color: inherit;
  padding-bottom: 1em;
}
tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}
th {
  display: table-cell;
  vertical-align: inherit;
  font-weight: bold;
  text-align: -internal-center;
  padding: 0;
  font-weight: normal;
  padding-left: 1em;
}
.total-line__price {
  padding-left: 1.5em;
  text-align: right;
  white-space: nowrap;
}
tfoot {
  display: table-footer-group;
  vertical-align: middle;
  border-color: inherit;
  border-top: 1px solid lightgrey;
  margin-top: 1em;
}
.total-line-table__tbody + .total-line-table__tbody .total-line:first-child th,
.total-line-table__tbody + .total-line-table__tbody .total-line:first-child td,
.total-line-table__tbody + .total-line-table__footer .total-line:first-child th,
.total-line-table__tbody
  + .total-line-table__footer
  .total-line:first-child
  td {
  padding-top: 1em;
  position: relative;
}
.sidebar .payment-due-label__total {
  color: #323232;
}

.payment-due-label__total {
  font-size: 1.1428571429em;
}
.payment-due__currency {
  font-size: 0.8571428571em;
  vertical-align: 0.2em;
  margin-right: 0.5em;
}

td:last-child,
th:last-child {
  text-align: right;
}

.sidebar .payment-due__currency {
  color: #717171;
}

.payment-due__price {
  font-size: 1.7142857143em;
  font-weight: 500;
  letter-spacing: -0.04em;
  line-height: 1em;
}
.total-line--shipping .total-line__name,
.total-line--shipping .total-line__price {
  padding-bottom: 2em;
}
