.thankyouMsg{
  font-size: 26px;
    line-height: 34px;
    margin: 10px 0;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0px;
    font-family: Nanum Myeongjo,serif;
}
@media screen and (max-width: 991px){
.thankyouMsg {
    text-align: center;
    padding-top: 30px;
}}
.link:hover{
  text-decoration: none;
  color:black;
}
.preparing{
  margin-top: -18px;
  margin-bottom: 18px;
  font-size: 14px;
  font-family: Nanum Gothic,sans-serif;
  font-weight: 400;
    font-style: normal;
    line-height: 32px;
    letter-spacing: 0;
    -webkit-font-smoothing: antialiased;
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

@media screen and (max-width: 991px){
.preparing {
    text-align: center;
    padding-top: 30px;
}
}
table{
  display: table;
  border-collapse: separate;
  box-sizing: border-box;
  text-indent: initial;
  border-spacing: 2px;
  border-color: grey;
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 1em;

}
tbody {
  display: table-row-group;
  vertical-align: middle;
  border-color: inherit;
}
tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}

th {
  display: table-cell;
  vertical-align: inherit;
  padding-left: 1em;
  padding-top: 0;
  padding-bottom: 0;
}
.productDescription{
  text-align: left;
  width: 100%;
}
.product-titleT{
  display: block;
  width: 1em;
  min-width: 100%;
  font-weight: 500;

}
.product-titleT a{
  font-size: 1.2em;
  font-weight: 600;
  color: black;
}
.status{
  display: block;
}
.paymentStatus{
  color: #5BAE50;
}
.fullDate{
  display: block;
}
.time{
  color: #717171;
  font-size: 1em;
  white-space: pre-line;
}
.chevronR{
  padding-left: 1em;
}
.tableWrapper{
  padding: 1.5em 0;
  border-bottom: 1px solid rgba(175,175,175,0.34);
  border-top: 1px solid rgba(175,175,175,0.34);

}
.tableWrapper ~ .tableWrapper{
  padding: 1.5em 0;
  border-bottom: 1px solid rgba(175,175,175,0.34);
  border-top: 1px solid transparent;
}
/* so keep the first wrapper top border only */
.productTable tr{
}
.borders{
  padding: 1em 0;
  border-bottom: 1px solid rgba(175,175,175,0.34);
  border-top: 1px solid rgba(175,175,175,0.34);

}
.orderBreaker{
  padding: 1em;
}
.rocket{
  font-size: 4.6em;
}

.addressOrder{
  display: block;
}
.ri-map-pin-2-line{
font-style: normal;
font-size: 18px;
padding: 0 0.15em;
line-height: 1;
letter-spacing: normal;
text-transform: none;
display: inline-block;
white-space: nowrap;
word-wrap: normal;
direction: ltr;
-webkit-font-feature-settings: 'liga';
-webkit-font-smoothing: antialiased;
}
.fa-brands{
    font-style: normal;
    font-size: 18px;
    padding: 0 .2em;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;

}
a{
  color: inherit;
}

td div{
  height:2.4em
}
.orderDownbar{
  background: #fafafa;
/*     border-top: 1px solid #e1e1e1;
 */    display: block;
    padding: 1em;

}
@media (min-width: 1000px){
.orderDownbar {
    display: none;
}}
.product__description__name {
  width: 1em;
  width: 86%;
}
.product__description {
padding-right: 1em;}
.orderDownbar .product__price{
  padding-right: 4px;
}
.orderSidebar{
display: none;
}
@media (min-width: 1000px) {
  .page--no-banner .main,
  .page--no-banner .sidebar {
    padding-top: 4em;
  }
}

@media (min-width: 1000px) {
  .orderSidebar {
    width: 38%;
    padding-left: 4%;
    background-position: left top;
    float: right;
    padding-top: 4em;
    background: #fafafa;
    border-left: 1px solid #e1e1e1;
    display: block;
  }
}
.orderSidebar::after {
  content: "";
  display: block;
  width: 300%;
  position: absolute;
  top: 0;
  left: -100%;
  bottom: 0;
  background:linear-gradient(90deg, white 0%, white 59%, #fafafa 59%, #fafafa 100%); ;
  z-index: -1;
  -webkit-box-shadow: 0 -1px 0 #e1e1e1 inset;
  box-shadow: 0 -1px 0 #e1e1e1 inset;
}
@media (min-width: 1000px) {
  .orderSidebar::after {
    left: 0;
    background-position: left top;
    -webkit-box-shadow: 1px 0 0 #e1e1e1 inset;
    box-shadow: 1px 0 0 #e1e1e1 inset;
  }
}