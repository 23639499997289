html, body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;    line-height: 1.3em;
    /* general rule line height .75em */
    font-size: 14px;
    overflow-x: hidden;
    -webkit-font-smoothing: subpixel-antialiased;
    background: white;
  }

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  
}
p, a {
  margin: 0;
  padding: 0;
}
.success{
  color: #007600;
  font-size: 1.8rem!important;
  line-height: 1.25!important;

}
.error{
  color: #B12704!important;
  font-size: 1.8rem!important;
    line-height: 1.25!important;
}