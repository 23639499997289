@media (min-width: 700px){
    .SiteHeader-notice_bar {
display: none;    
    }
    }
    .SiteHeader-notice_bar {
      width: 100%;
      height: 24px;
      font-family: Nanum Gothic,sans-serif;
      font-size: 14px;
      font-weight: 700;
      line-height: 1.7;
      background-color:#16242f;
      color: #d4d2d2;
      transition: all .3s ease-in-out;
      position: absolute;
      z-index: 10;
    }
    .SiteHeader-notice {
      text-align: center;
      margin: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    
    .SiteHeader-notice_bar .SiteHeader-notice-text {
      margin: 0 auto;
      padding: 0 30px;
      font-size: 12px;
      line-height: 13px;
      font-weight: 700;
      width: 100%;
      color: #d4d2d2;
    
    }
    
    
    .SiteHeader-notice_bar a{
      background-color: transparent;
        text-decoration: none;
    
    }
    .SiteHeader-notice .close-notice-bar {
      position: absolute;
      right: 20px;
    }
    
    .SiteHeader-notice .close-notice-bar:before {
      line-height: 1;
      color: inherit;
      font-size: 11px;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
    .SiteHeader-notice_bar.SiteHeader-notice_bar__closed {
      height: 0;
      opacity: 0;
      visibility: hidden;
    }
    @media (max-width: 1024px){
    .SiteHeader-notice_bar {
        height: 24px;
    }}
    