.sidebar{
  display: none;
}
@media (min-width: 1000px) {
  .page--no-banner .main,
  .page--no-banner .sidebar {
    padding-top: 4em;
  }
}

@media (min-width: 1000px) {
  .sidebar {
    width: 38%;
    padding-left: 4%;
    background-position: left top;
    float: right;
    padding-top: 4em;
    background: #fafafa;
    border-left: 1px solid #e1e1e1;
    display: block;
  }
}
.sidebar::after {
  content: "";
  display: block;
  width: 300%;
  position: absolute;
  top: 0;
  left: -100%;
  bottom: 0;
  background:linear-gradient(90deg, white 0%, white 59%, #fafafa 59%, #fafafa 100%); ;
  z-index: -1;
  -webkit-box-shadow: 0 -1px 0 #e1e1e1 inset;
  box-shadow: 0 -1px 0 #e1e1e1 inset;
}
@media (min-width: 1000px) {
  .sidebar::after {
    left: 0;
    background-position: left top;
    -webkit-box-shadow: 1px 0 0 #e1e1e1 inset;
    box-shadow: 1px 0 0 #e1e1e1 inset;
  }
}
.orderSummary .txt {
  width: 52vw;
  color: #e7b405;
}
.orderSummary .amount {
  font-weight: 500;
  font-size: 1.2rem;
  width: 100%;
  text-align: right;
  /*     background: red;
 */
}
.orderSummary svg {
  fill: #e7b405;
  width: 6vw;
  padding-right: 0.75em;
  padding-top: 0.2em;
}
.orderSummary span {
  color: #e7b405;
  font-size: 1.2rem;
  line-height: 1.3em;
}
@media (min-width: 1000px) {
  .order-summary__sections {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    height: calc(100vh - 8em);
    width: 100%;
  }
}

.expandLess {
  overflow: hidden;
  max-height: 0;
}
@media (min-width: 1000px) {
  .order-summary__section--product-list::before,
  .order-summary__section--product-list::after {
    content: "";
    display: block;
    height: 1px;
    left: 0;
    position: absolute;
    -webkit-transition: background-color 0.3s cubic-bezier(0.3, 0, 0, 1);
    transition: background-color 0.3s cubic-bezier(0.3, 0, 0, 1);
    width: 100%;
    z-index: 4;
  }
}
@media (min-width: 1000px) {
  .order-summary__section--product-list::before,
  .order-summary__section--product-list::after {
    content: "";
    display: block;
    height: 1px;
    left: 0;
    position: absolute;
    -webkit-transition: background-color 0.3s cubic-bezier(0.3, 0, 0, 1);
    transition: background-color 0.3s cubic-bezier(0.3, 0, 0, 1);
    width: 100%;
    z-index: 4;
  }
}
@media (min-width: 1000px) {
  .order-summary__section--product-list::after {
    background-color: rgba(175, 175, 175, 0.34);
    bottom: 0;
  }
}

.product-picS img,
.product-picS {
  width: 4.6em;
  height: 4.6em;
  border-radius: 8px;
  background: #fff;
  position: relative;
  border: 1px rgba(0, 0, 0, 0.1) solid;
}
@media (min-width: 1000px) {
  .order-summary__section:first-child {
    padding-top: 0;
  }
}
@media (min-width: 1000px) {
  .order-summary__section--product-list {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    min-height: 7em;
    overflow: hidden;
    padding: 0;
    position: relative;
  }
}

@media (min-width: 1000px) {
  .order-summary__section--product-list .product-table {
    margin-top: 0.75em;
    margin-bottom: 1.5em;
    position: relative;
    z-index: 1;
  }
}
td:first-child,
th:first-child {
  padding-left: 0;
  text-align: left;
}
.product-table th,
.product-table td {
  padding-top: 0;
  padding-bottom: 0;
}
product:first-child td {
  padding-top: 0;
}

.cartCircleS {
  width: 1.75em;
  height: 1.75em;
  border-radius: 5vw;
  position: absolute;
  right: -0.75em;
  top: -0.75em;
  /*         transform: translateY(-90%);
 */
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background: rgba(114, 114, 114, 0.9);
}
.product-picS p {
  font-size: 1em;
  font-weight: 700;
  /*      padding: 0 0 5vw 2vw; 
     position: absolute;
 */
}

.product__description {
  text-align: left;
  width: 100%;
}
.sidebar .order-summary__emphasis {
  color: #323232;
}
.product__description__name {
  width: 1em;
  min-width: 100%;
}
.order-summary__emphasis {
  font-weight: 500;
}
td:last-child,
th:last-child {
  text-align: right;
}
.order-summary__section ~ .order-summary__section {
  border-top: 1px solid rgba(175, 175, 175, 0.34);
}
.order-summary__section {
  padding-top: 1.5em;
  padding-bottom: 1.5em;
  display: block;
}
table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 1em;
}
tbody {
  display: table-row-group;
  vertical-align: middle;
  border-color: inherit;
  padding-bottom: 1em;
}
tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}
th {
  display: table-cell;
  vertical-align: inherit;
  font-weight: bold;
  text-align: -internal-center;
  padding: 0;
  font-weight: normal;
  padding-left: 1em;
}
.total-line__price {
  padding-left: 1.5em;
  text-align: right;
  white-space: nowrap;
}
tfoot {
  display: table-footer-group;
  vertical-align: middle;
  border-color: inherit;
  border-top: 1px solid lightgrey;
  margin-top: 1em;
}
.total-line-table__tbody + .total-line-table__tbody .total-line:first-child th,
.total-line-table__tbody + .total-line-table__tbody .total-line:first-child td,
.total-line-table__tbody + .total-line-table__footer .total-line:first-child th,
.total-line-table__tbody
  + .total-line-table__footer
  .total-line:first-child
  td {
  padding-top: 1em;
  position: relative;
}
.sidebar .payment-due-label__total {
  color: #323232;
}

.payment-due-label__total {
  font-size: 1.1428571429em;
}
.payment-due__currency {
  font-size: 0.8571428571em;
  vertical-align: 0.2em;
  margin-right: 0.5em;
}

td:last-child,
th:last-child {
  text-align: right;
}

.sidebar .payment-due__currency {
  color: #717171;
}

.payment-due__price {
  font-size: 1.7142857143em;
  font-weight: 500;
  letter-spacing: -0.04em;
  line-height: 1em;
}
.total-line--shipping .total-line__name,
.total-line--shipping .total-line__price {
  padding-bottom: 2em;
}
