.contentp {
    padding: 1rem;
  }
  .details {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  /*  flex-wrap: wrap;*/
    flex-direction: column;
    
  }
  .details-header, .details-header div{
    display: flex;
    align-items: center;
    /*background: greenyellow;*/
    justify-content: space-between;
    /*will put both childs at each end equally*/
  }
  .details-header p{
    margin-left: 2vw;
    font-size: .9rem
  }
  .back-to-result a, .details p{
    font-size: .9rem
  }
  .small-rating{
    display: flex;
  
    /*background: darkorange;*/
  }
  .small-rating span{
    color: #f08804;
   /* -webkit-text-stroke: .3vw #f08804;*/
   
    font-size: 4vw;
    margin-right: -.9vw;
    margin-top: -.5vw;
   /* background: sandybrown;*/
  }
  .bestseller{
    background: #C25501;
    border-radius: .2rem;
    width: 22vw;
    height: 4.9vw;
    position: absolute;
    /*clip-path: url("#clip");*/
  }
  .flag p{
      position: relative;
      color: white;
      font-size: .9rem;
      padding: .2rem .5rem;
      margin-top: -.2rem
  }
  .mascara{
  margin-right: 50vw;
  margin-top: .4rem;
  font-size:1rem;
  }
  .flag{
    /*background: skyblue;*/
    position: relative;
    margin-top: .3rem;
    
  }
  .flag svg{
   /* background: hotpink;*/
    position: absolute;
    
  }
  .details-image {
    width: 92vw;
  }
  .details-image img {
   max-width: 60rem;
    width: 100%;
  }
  .content-divider{
    background: #00000014;
    width: 150vw;
    height:1.5vw;
   /* overflow-x: visible;
    box-sizing: content-box;
    flex-wrap: nowrap;*/
    margin-left: -4vw;
    margin-top: 4vw;
    margin-bottom: 4vw;
  
  }
  hr{
    border: 0;
    border-top: 1px solid #CCC;
  }
  .details-info .bd{
    font-size: 3rem;
    font-weight: 350
    
  }
  .details-info .fils{
    font-size: 1rem;
    
  }
  .details-info,
  .details-action {
    flex: 1 1 30rem;
  }
  .details-info ul,
  .details-action ul {
    padding: 0;
    list-style-type: none;
  }
  .details-info h1 {
    font-size: 2rem;
    margin: 0;
  }
  .details-info li,
  .details-action li {
    margin-bottom: 1rem;
  }
  .details-info .success{
    font-size: 1.5rem
  }
  .details-action {
    border: 0.1rem #808080 solid;
    border-radius: 0.5rem;
    background-color: #f8f8f8;
    padding: 1rem;
  }
  button {
    font: 1.6rem helvetica;
    padding: 1rem;
    border: 0.1rem #808080 solid;
    border-radius: 0.5rem;
    cursor: pointer;
  }
  button:hover {
    border: 0.1rem #404040 solid;
  }
  a {
    color: #337ab7;
    text-decoration: none;
}  
