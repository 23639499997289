.card {
    margin-bottom: 1.5rem;
    box-shadow: 0 1px 2px rgb(56 65 74 / 15%);
}

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: var(--vz-card-bg);
    background-clip: border-box;
    border: 0 solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
}

.card-header:first-child {
    border-radius: 0.25rem 0.25rem 0 0;
}

.card-header {
    border-bottom: 1px solid #e9ebec;
}

.d-flex {
    display: -webkit-box!important;
    display: -ms-flexbox!important;
    display: flex!important;
}
.align-items-center {
    align-items: center !important;
}

.card-title {
    font-size: 16px;
    margin: 0 0 7px 0;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.flex-grow-1 {
    flex-grow: 1 !important;
}

.mt-2 {
    margin-top: 0.5rem !important;
}

.flex-shrink-0 {
    flex-shrink: 0 !important;
}


.mt-2 {
    margin-top: 0.5rem !important;
}

.btn-sm, .btn-group-sm > .btn {
    padding: 0.25rem 0.5rem;
    font-size: 0.71094rem;
    border-radius: 0.2rem;
}

.btn {
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.5rem 0.9rem;
    font-size: 0.8125rem;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn-soft-danger {
    color: #e1706a;
    background-color: rgba(225, 112, 106, 0.1);
    border-color: transparent;
}

.card-body {
    flex: 1 1 auto;
    padding: 1rem 1rem;
}
.accordion-item {
    background-color: #fff;
    border: 1px solid #e9ebec;
}

.border-0 {
    border: 0 !important;
}
.profile-timeline .accordion-item::before {
    content: "";
    border-left: 2px dashed #e9ebec;
    position: absolute;
    height: 100%;
    left: 21px;
}
.profile-timeline .accordion-item:first-child::before {
    top: 8px;
}

.accordion-flush .accordion-item {
    border-right: 0;
    border-left: 0;
    border-radius: 0;
}
.accordion-flush .accordion-collapse h6{
    color: #495057;
    font-size: .875rem;

}
.accordion-flush p{
    color: #878a99!important;
    margin-top: 0;
    margin-bottom: 1rem;

}

.profile-timeline .accordion-item {
    position: relative;
}

.accordion-header {
    margin-bottom: 0;
}

.accordion-button::after {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: 0.75rem;
    height: 0.75rem;
    margin-left: auto;
    content: "";
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='var%28--vz-body-color%29'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-size: 0.75rem;
    -webkit-transition: -webkit-transform .2s ease-in-out;
    transition: -webkit-transform .2s ease-in-out;
    transition: transform .2s ease-in-out;
    transition: transform .2s ease-in-out,-webkit-transform .2s ease-in-out;
}
.accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%235593b7'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
}
.profile-timeline .accordion-item .accordion-button::after {
    background: 0 0;
}

.accordion-button {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 1rem 1.25rem;
    font-size: 0.8125rem;
    color: var(--vz-body-color);
    text-align: left;
    background-color: var(--vz-card-bg);
    border: 0;
    border-radius: 0;
    overflow-anchor: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
}
.shadow-none {
    box-shadow: none !important;
}
.p-2 {
    padding: 0.5rem !important;
}
.accordion-button:not(.collapsed) {
    color: #283946;
    background-color: rgba(44, 63, 78, 0.05);
    box-shadow: inset 0 -1px 0 var(--vz-border-color);
}
.accordion .accordion-button {
    font-weight: 500;
}
.accordion-flush .accordion-item .accordion-button {
    border-radius: 0;
}
.profile-timeline .accordion-item .accordion-button {
    background-color: transparent;
}

.flex-shrink-0 {
    flex-shrink: 0 !important;
}
.avatar-xs {
    height: 2rem;
    width: 2rem;
}
.rounded-circle {
    border-radius: 50% !important;
}
.bg-light {
    --vz-bg-opacity: 1;
    background-color: rgba(243,246,249,1)!important;
}
.avatar-title {
    align-items: center;
    background-color: #2c3f4e;
    color: #fff;
    display: flex;
    font-weight: 500;
    height: 100%;
    justify-content: center;
    width: 100%;
}
.bg-success {
    --vz-bg-opacity: 1;
    background-color: rgba(73, 129, 173, 1) !important;
}
.ri-shopping-bag-line:before {
    content: "\f118";
}
.avatar-title {
    align-items: center;
    background-color: #2c3f4e;
    color: #fff;
    display: flex;
    font-weight: 500;
    height: 100%;
    justify-content: center;
    width: 100%;
}

.mdi-set, .mdi:before {
    display: inline-block;
    font: normal normal normal 24px/1 "Material Design Icons";
    font-size: inherit;
    text-rendering: auto;
    line-height: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
[class^="ri-"], [class*=" ri-"] {
    font-family: 'remixicon' !important;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.text-success {
    --vz-text-opacity: 1;
    color: rgba(73, 129, 173, 1) !important;
}

.ms-3 {
    margin-left: 1rem !important;
}
.flex-grow-1 {
    flex-grow: 1 !important;
}
h6 {
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-weight: 600;
    line-height: 1.2;
}
.fs-15 {
    font-size: 15px !important;
}
.fw-semibold {
    font-weight: 600 !important;
}
.fs-14 {
    font-size: 14px!important;
}
.fw-normal {
    font-weight: 400 !important;
}
.accordion-flush .accordion-collapse {
    border-width: 0;
}
.accordion-body {
    padding: 1rem 1.25rem;
}
.ms-2 {
    margin-left: 0.5rem !important;
}
.pt-0 {
    padding-top: 0 !important;
}
.ps-5 {
    padding-left: 3rem !important;
}
.accordion .accordion-body {
    color: #878a99;
}

.mb-1 {
    margin-bottom: 0.25rem !important;
}
.h6 {
    color:#495057;
    font-family: "Poppins", sans-serif;
}
.profile-timeline .accordion-item:first-child::before {
    /* top: 8px; */
}
