.giftForm{
    display: flex;
    flex-direction: row;
}
.giftInput{
    flex:5;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    color: #545454;
    padding: .5em;
}
.giftBtn{
    flex:1;
    border: 0;
    display: flex;
    margin-left: .5em;
    padding: .5em;
    align-items: center;
    justify-content: center;
}
.couponConfirmationContainer{
    background-color: lightgray;
    width: fit-content;
    padding: .5em;
    margin: .5em 0;
    border-radius: 5px;
}